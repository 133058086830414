@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
	font-family: 'Lato';
	src: url('../fonts/lato-regular-webfont.woff2') format('woff2'),
		url('../fonts/lato-regular-webfont.woff') format('woff');
	font-weight: 400;
	font-weight: 'normal' 'regular';
	font-style: normal;
}

@font-face {
	font-family: 'Lato';
	src: url('../fonts/lato-bold-webfont.woff2') format('woff2'),
		url('../fonts/lato-bold-webfont.woff') format('woff');
	font-weight: 500 700;
	font-weight: 'medium' 'bold';
	font-style: normal;
}

@font-face {
	font-family: 'Lato';
	src: url('../fonts/lato-black-webfont.woff2') format('woff2'),
		url('../fonts/lato-black-webfont.woff') format('woff');
	font-weight: 800 900;
	font-weight: 'black' 'heavy';
	font-style: normal;
}

html {
	font-family: 'Lato';
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

#full-report .MuiButton-outlined {
	border-width: 2px !important;
	background: linear-gradient(
		90deg,
		#0a71d0,
		#0a71d0 50%,
		transparent 0,
		transparent
	);
	background-position: 100% 100%;
	background-repeat: no-repeat;
	background-size: 200% 100%;
	transition: background-position 0.25s ease-out;
	border-color: #0a71d0;
}

#full-report .MuiButton-outlined:hover {
	color: white;
	background-position: 0 100% !important;
	background: linear-gradient(
		90deg,
		#0a71d0,
		#0a71d0 50%,
		transparent 0,
		transparent
	);
	background-position: 100% 100%;
	background-repeat: no-repeat;
	background-size: 200% 100%;
	transition: background-position 0.25s ease-out;
}

/* @media (min-width: 1200px) {
	.stageLevel:before {
		border-bottom: 20px solid #a2d7ef;
		border-left: 55px solid transparent;
		border-right: 55px solid transparent;
	}
	.stageLevel {
		width: 110px;
	}
} */
@media (min-width: 601px) {
	.stageLevel:before {
		border-bottom: 20px solid #a2d7ef;
		border-left: 55px solid transparent;
		border-right: 55px solid transparent;
	}
	.stageLevel {
		width: 110px;
	}
}
@media (max-width: 600px) {
	.stageLevel:before {
		border-bottom: 20px solid #a2d7ef;
		border-left: 20px solid transparent;
		border-right: 20px solid transparent;
	}
	.stageLevel {
		width: 40px;
	}
}

.stageLevel {
	background: #a2d7ef;
	display: inline-block;
	margin-top: 25px;
	position: relative;
}
.stageLevel.selected {
	background: #0d2345;
}
.stageLevel.selected:before {
	border-bottom: solid 20px #0d2345;
}

.stageLevel:before {
	content: '';
	height: 0;
	left: 0;
	position: absolute;
	top: -20px;
	width: 0;
}

.Toastify__toast,
.Toastify__toast-body {
	padding: 0 !important;
	min-height: 40px !important;
}

.Toastify__toast-body {
	margin: 0 !important;
}

@media print {
	@page {
		size: landscape;
	}
	body * {
		visibility: hidden;
	}
	.MuiAppBar-root {
		display: none;
	}
	.section-to-print,
	.section-to-print * {
		visibility: visible;
		print-color-adjust: exact;
	}
	.section-to-print {
		margin-top: 0;
		color: black !important;
	}
	.section-to-print .icon-text-block .svg-inline--fa {
		font-size: 40px;
	}
	.section-to-print.section-title {
		font-size: 1.75rem;
		line-height: 2rem;
	}
	.section-to-print h3,
	.section-to-print h4,
	.section-to-print h5 {
		font-size: 1.25rem !important;
	}
	.section-to-print h2 {
		font-size: 1.5rem;
	}
	.section-to-print .text-lg {
		font-size: 15px;
	}
	.page-break {
		page-break-after: always;
	}
	.MuiAccordionSummary-root {
		padding: 0;
	}
	.schedule-consultation-button {
		padding-top: 0 !important;
	}
	.section-to-print .international-institute-for-analytics {
		width: 128px;
	}
}
